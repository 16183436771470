*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans', sans-serif;
  font-display: swap;

  -webkit-user-select: none;
    -moz-user-select: -moz-none;
    /*IE10*/
    -ms-user-select: none;
    user-select: none;

    /*You just need this if you are only concerned with android and not desktop browsers.*/
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/* :root{
	background: #1A1A1A;
} */
*::selection 
{
    background-color:transparent;
} 
*::-moz-selection
{
    background-color:transparent;
}
@font-face {
  font-family: 'Encode Sans';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/encode-sans-v8-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/encode-sans-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/encode-sans-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/encode-sans-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/encode-sans-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/encode-sans-v8-latin-regular.svg#EncodeSans') format('svg'); /* Legacy iOS */
}
.spinner-wrapper {
	position: fixed;
	z-index: 999999;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: #fff;
}

.spinner {
	position: absolute;
	top: 50%; /* centers the loading animation vertically one the screen */
	left: 50%; /* centers the loading animation horizontally one the screen */
	width: 3.75rem;
	height: 1.25rem;
	margin: -0.625rem 0 0 -1.875rem; /* is width and height divided by two */ 
	text-align: center;
}

.spinner > div {
	display: inline-block;
	width: 1rem;
	height: 1rem;
	border-radius: 100%;
	background-color: #ffa800;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
	0%, 80%, 100% { -webkit-transform: scale(0); }
	40% { -webkit-transform: scale(1.0); }
}

@keyframes sk-bouncedelay {
	0%, 80%, 100% { 
		-webkit-transform: scale(0);
		-ms-transform: scale(0);
		transform: scale(0);
	} 40% { 
		-webkit-transform: scale(1.0);
		-ms-transform: scale(1.0);
		transform: scale(1.0);
	}
}
