#modernize{
    background: transparent url('https://res.cloudinary.com/starla/image/upload/f_auto/v1625319871/curve_yhvotf.png') no-repeat;
    /* background: transparent url('../../images/D.svg') no-repeat; */
    background-position: left 50% top 0%;
    background-size: 160% auto;
}
.together{
    display: flex;
}
@media screen and (max-width: 767px){
    .together{
        display: flex;
        justify-content: center;
    }
}
















@media (min-width: 768px){
    .feature__arrow {
        right: 30px;
        width: 40%;
        height: 2px !important;
        background: #e8e7eb;
        top: 50px;
        left: 10%;
        margin-right: 20px;
        display: block;
        position: relative;
    }
}
@media (min-width: 980px){
    .feature__arrow {
        right: 30px;
        width: 40%;
        height: 2px !important;
        background: #e8e7eb;
        top: 50px;
        left: 10%;
        margin-right: 20px;
        display: block;
        position: relative;
    }
}
@media (min-width: 768px){
.feature__arrow::before, .feature__arrow::after {
    display: block;
    content: '';
    position: absolute;
    right: -2px;
    bottom: 5px;
    height: 2px;
    width: 15px;
    background: #e8e7eb;
    transform: rotate(45deg);
}
}
@media (min-width: 768px){
.feature__arrow::after {
    bottom: -5px;
    transform: rotate(-45deg);
}
}

@media (min-width: 768px){
.feature__arrow::before, .feature__arrow::after {
    display: block;
    content: '';
    position: absolute;
    right: -2px;
    height: 2px;
    width: 15px;
    background: #e8e7eb;
}
}











.image45{
    height: 190px;
    width: 266.14px;
}
@media screen and (max-width: 877px){
    .image45{
        height: 180px;
        width: 216.14px;
    }
} 
@media screen and (max-width: 767px){
    .image45{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

#numberone{
    background: #fff;
    border-radius: 50%;
    display: inline-block;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 12px;
    padding-left: 12px;
    margin-right: 10px;
    font-size: 14px;
    bottom: 4px;
    position: relative;
    border: 2px solid #ffa800;
}
@media screen and (max-width: 835px){
    #numberone{
        text-align: center;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 9px;
        padding-left: 9px;
        margin-right: 10px;
        font-size: 13px;
        bottom: 0px;
    }
}
@media screen and (max-width: 767px){
    #numberone{
        text-align: center;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-right: 9px;
        padding-left: 9px;
        margin-right: 10px;
        font-size: 13px;
        bottom: 0px;
    }
}