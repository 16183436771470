.pctemplateforvideo{
    /* margin-top: 110px;
    height: 580px;
    width: auto; */
    margin-top: 60px;
    height: 498px;
    width: 613.86px;
}
.introvideo{
    position: absolute;
    margin-top: 81px;
    height: 330px;
    width: 589.29px;
}
@media only screen and (max-width:1250px){
    .introvideo{
        height: 294px;
        width: 525px;
        margin-top: 101px;
    }
       .pctemplateforvideo{
           height: 450px;
           width: 560px;
           margin-top:80px;
       }
   
   }


@media only screen and (max-width:960px){
 .introvideo{
    height: 294px;
    width: 525px;
        margin-top: 131px;
    }
    .pctemplateforvideo{
        height: 450px;
        width: 560px;
        margin-top:109px;
    }

}

@media only screen and (max-width:600px){
    .introvideo{
        position: absolute;
        height: 178px;
        width: 317.86px;
     
    }
    .pctemplateforvideo{
        height: 269px;
        width: 341px;
        margin-top:120px;
    }
}
@media only screen and (max-width:340px){
    .introvideo{
        position: absolute;
        height: 137px;
        width: 244.64px;
        margin-top: 120px;
    }
    .pctemplateforvideo{
        height: 207px;
        width: 260px;
        margin-top:110px;
    }
}
@media only screen and (max-width: 280px){
    .introvideo{
        position: absolute;
        height: 122px;
        width: 2000px;
        margin-top: 118px;
    }
    .pctemplateforvideo{
        height: 183px;
        width: auto;
    }
}
video::-webkit-media-controls {
    display:none !important;
}