.CircularProgressbar-path{
    stroke: #0CCE6B !important;
     stroke-width:1px;
} 

.CircularProgressbar-trail{
    stroke: #E7FAF0 !important;
    stroke-width: 1px;
}
.CircularProgressbar-text{
    
    stroke: #0CCE6B !important;
    font-size: 28px !important;
    font-weight: 100 !important; 
}
.statssize{
    width: 170px;
    height: 170px;
    margin: auto;
    
}
@media only screen and (max-width: 670px) {
    .statssize{
        width: 140px;
        height: 140px;
    }}

@media only screen and (max-width: 600px) {
.statssize{
    width: 120px;
    height: 120px;
}}
@media only screen and (max-width: 500px) {
    .statssize{
        width: 100px;
        height: 100px;
    }}